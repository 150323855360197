@import "utils/mixins.scss";
@import "utils/colors.scss";

.layout {
  max-width: 100vw;
  overflow-x: hidden;

  .MuiAppBar-colorPrimary {
    background-color: $black;
  }
  .MuiAppBar-colorPrimary.primary-appbar {
    background-color: $primary-color;
  }
  .appbar {
    transition: 0.2s;
    .toolbar {
      justify-content: space-between;
      flex-direction: row;
      .MuiListItem-padding {
        padding: 0;
      }
      .navigation-list {
        @include horizontal-list;
        .navigation-item {
          padding-top: 0;
          padding-bottom: 0;
        }
        span {
          white-space: nowrap;
          font-weight: 600;
          background-color: transparent;
        }
      }
      .selected-item-in-menu {
        @include primary-on-white;
      }
      .right-menu {
        .lang-list {
          @include horizontal-list;
          .language-button {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
        .logo-container {
          height: 1em;
          .icon-button {
            height: 1em;
            width: 1em;
          }
        }
      }
      .main-menu-logo {
        height: 32px;
        width: 32px;
        margin-left: 15px;
        transform: scale(3.5);
      }
    }
  }
  .mobile-appbar {
    color: $primary-color;
    overflow-y: hidden;
    .right-mobile-menu {
      color: $primary-color;
      @include horizontal-list;
      .menu-icon {
        margin-left: 10px;
        height: 32px;
        width: 32px;
      }
    }
  }
  .mobile-appbar.primary-appbar {
    background-color: black;
  }

  .mobile-appbar:active {
    background-color: transparent;
  }

  .content-container {
    min-height: 100vh;
  }

  .contact-us-section {
    padding: 12px 0;
    .contact-us-button {
      margin: 10px auto 20px;
      display: block;
      background-color: white;
      border: 2px solid $primary-color;
      height: 2em;
      width: 400px;
    }
  }
  .black-on-white {
    @include black-on-white;
  }
  .white-on-black {
    @include white-on-black;
  }

  .footer {
    @include horizontal-list;
    @include white-on-primary;
    justify-content: space-between;
    a {
      color: white;
    }
    .icon-button {
      height: 32px;
      width: 32px;
    }
    .icon-button:hover {
      background-color: rgba(255, 255, 255, 0.2);
      color: black;
    }
    .footer-link {
      text-decoration: none;
      margin: 0 1em;
    }
    .footer-link:hover {
      color: black;
    }
  }

  .preview-image-container {
    aspect-ratio: 3 / 2;
    position: relative;
    color: white;
    .preview-content {
      position: absolute;
      max-height: calc(100% - 10px);
      overflow: hidden;
      width: calc(100% - 10px);
      background: linear-gradient(180deg, rgba(234, 55, 94, 0) 0%, rgba(0, 0, 0, 1) 100%);
      bottom: 0;
      padding: 5px;
    }
    .hover-container {
      height: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      overflow: hidden;
      transition: 0.2s;
    }
    .non-hoverable {
      height: 100%;
    }
  }
  .preview-image-container:hover {
    .preview-content {
      visibility: hidden;
    }
    .hover-container {
      height: 100%;
    }
  }

  .icon-button {
    color: inherit;
  }
  .horizontal-list {
    @include horizontal-list;
  }
  .markdown-container {
    a {
      color: $primary-color;
      text-decoration-color: $primary-color;
    }
    a:visited {
      color: $primary-color;
      text-decoration-color: $primary-color;
    }
  }
}
.loading-indicator {
  margin: 1em;
  color: $primary-color !important;
}
.datepicker-container {
  .datepicker-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    svg {
      height: 20px;
      width: auto;
    }
  }
  .datepicker-months-container {
    text-align: center;
    width: 100%;
    margin-top: 10px;
    .datepicker-month {
      cursor: pointer;
      .datepicker-month-text {
        font-weight: 700;
      }
      :hover {
        background-color: $primary-translucid;
        border-radius: 5px;
      }
    }
    .selected-month {
      color: $primary-color;
    }
  }
}
.menu-drawer {
  .MuiPaper-root {
    min-width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .navigation-item {
      padding-left: 0;
    }
    .bottom-menu-navigation {
      background-color: rgba(128, 128, 128, 0.174);
    }
    .lang-list {
      display: flex;
      flex-direction: row;
      background-color: $primary-translucid;
      align-items: center;
      .primary-color-button {
        color: $primary-color;
      }
    }
  }
}
@media (max-width: 900px) {
  .layout {
    .contact-us-section {
      .contact-us-button {
        width: 200px;
      }
    }
    .footer {
      .footer-link {
        p {
          font-size: 1em;
        }
      }
    }
  }
}
