@import "/src/utils/colors.scss";
@import "/src/utils/mixins.scss";
.services {
    @include page-padding;
    scroll-behavior: smooth;
    .service-menu {
        padding: 24px 0;
        .service-menu-item {
            word-wrap: break-word;
            border: 5px solid $primary-color;
            padding: 0;
            text-align: center;
            cursor: pointer;
            .menu-item-content-container {
                padding: 0 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                .menu-item-title {
                    position: absolute;
                }
            }
        }
    }
    .services-content-container {
        padding-top: 50px;
        .service-container {
            margin: 100px 12px;
            opacity: 0;
            transform: translateX(-20vw);
            visibility: hidden;
            transition:
                opacity 1200ms ease-out,
                transform 600ms ease-out,
                visibility 1200ms ease-out;
            will-change: opacity, transform, visibility;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            .service-image-container {
                position: relative;
                .service-image {
                    width: calc(100% - 16px);
                    aspect-ratio: 3 / 2;
                    height: auto;
                }
                .see-more-button {
                    @include white-on-primary;
                    position: absolute;
                    left: 30px;
                    border-radius: 0;
                    bottom: 10px;
                }
                .see-more-button:hover {
                    @include primary-on-white;
                }
            }
            .service-title {
                color: $primary-color;
                text-align: center;
                margin-bottom: 12px;
            }
        }
        .right-to-left {
            transform: translateX(20vw);
            flex-direction: row-reverse;
            .see-more-button {
                right: 30px;
                left: auto !important;
            }
        }
        .service-container.is-visible {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }
}
@media (max-width: 900px) {
    .services {
        padding: 50px 20px 10px;
        .services-content-container {
            .service-container {
                flex-direction: column;
                text-align: center;
                margin-left: 0;
                width: 100%;
                .service-image-container {
                    position: relative;
                    padding-left: 16px;
                }
                .service-description-container {
                    padding-left: 0;
                }
            }
            .right-to-left {
                flex-direction: column;
            }
            .see-more-button {
                position: inherit !important;
                margin-top: 12px;
            }
        }
    }
}
