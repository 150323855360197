@import "utils/mixins.scss";
@import "utils/colors.scss";

.home-page {
    .home-page-header {
        position: relative;
        .carousel {
            position: relative;
        }
        .home-background-image-container {
            min-width: 100vw;
            height: 115vh;
        }
        .home-background-image {
            height: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            min-width: 100%;
        }
        .home-primary-logo-container {
            position: absolute;
            top: 0;
            height: 100vh;
            width: 100vw;
            .home-primary-logo {
                position: absolute;
                width: 60%;
                left: 0;
                right: 0;
                margin: auto;
                top: 0;
                bottom: 0;
            }
        }

        .home-corp-copy {
            padding: 10px 30px;
            position: absolute;
            width: calc(100% - 60px);
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00001) 0%, rgba(0, 0, 0, 1) 100%);
            color: white;
        }
    }

    .home-projects-section {
        margin-top: -5px;
        padding: 55px 30px;
        color: white;
        background: linear-gradient(black, black, $primary-color);
        .project-description {
            width: 100%;
            margin-top: 5px;
            max-height: calc(100% - 80px);
            line-height: 1.1rem;
            overflow: hidden;
        }
        .projects-preview-container {
            @include horizontal-list;
            margin-top: 30px;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            .project-preview {
                width: 30%;
                border: 5px solid $primary-color;

                .project-card {
                    padding: 5px 10px;
                    height: calc(100% - 10px);

                    .see-more-button-container {
                        position: absolute;
                        bottom: 10px;
                        left: -5px;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        .see-more-button {
                            @include white-on-primary;
                            border-radius: 0;
                        }
                        .see-more-button:hover {
                            @include primary-on-white;
                        }
                    }
                }
            }
        }
        .collaborators-preview-container {
            padding: 120px 0 30px;
            .carousel {
                margin-top: 30px;
                .client-container {
                    height: auto;
                }
                .avatar-img {
                    height: auto;
                    margin: 0 10px;
                    max-width: 300px;
                    img {
                        width: calc(100% - 20px);
                    }
                }
                .avatar-img:focus-visible {
                    outline: none;
                }
            }
        }

        .see-projects-button-container {
            margin-top: 20px;
            float: right;
            .see-projects-button {
                @include white-on-primary;
                border-radius: 0;
            }
        }
    }
    .home-services-section {
        margin-top: -1px;
        white-space: pre-line;
        padding: 11px 20px 80px;
        @include white-on-primary;

        .see-services-button-container {
            float: right;
            .see-services-button {
                @include primary-on-white;
                border-radius: 0;
            }
        }
    }
    .home-about-section {
        text-align: center;
        .about-us-img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90vh;
            overflow-y: hidden;
            .about-us-img {
                width: 100%;
                min-height: 100%;
            }
            .about-us-button-container {
                position: absolute;
                .about-us-button {
                    @include white-on-primary;
                    border-radius: 0;
                }
            }
        }
    }
}
@media (max-width: 900px) {
    .home-projects-section {
        .mobile-projects-preview {
            margin-top: 10px;
            .slick-arrow::before {
                color: $primary-color;
                opacity: 1;
            }
            .project-preview {
                border: 5px solid $primary-color;
                width: calc(100% - 10px) !important;

                .see-more-button-container {
                    position: absolute;
                    bottom: 10px;
                    left: -5px;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    .see-more-button {
                        @include white-on-primary;
                        border-radius: 0;
                    }
                    .see-more-button:hover {
                        @include primary-on-white;
                    }
                }
            }
        }
        .projects-preview-container {
            align-content: space-evenly;
            justify-content: center !important;

            .project-preview {
                width: 80% !important;
                margin: 10px 0;
            }
        }
        .collaborators-preview-container {
            .avatar-img {
                margin: 0 !important;
                img {
                    width: 100% !important;
                }
            }
        }
    }
    .home-services-section {
        .see-services-button-container {
            .see-services-button {
                margin-top: 10px;
            }
        }
    }
    .home-about-section {
        .about-us-img-container {
            height: auto !important;
        }
    }
}
@media (max-width: 1000px) {
    .home-page {
        .home-primary-logo {
            width: 80% !important;
        }
        .home-page-header {
            position: relative;
            overflow: hidden;

            .home-corp-copy {
                position: relative;
                margin-top: -5px;
                background-color: black;
            }
        }
    }
}
@media (max-width: 375px) {
    .project-description {
        max-height: 50px !important;
    }
}
