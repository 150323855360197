@import "/src/utils/colors.scss";
@import "/src/utils/mixins.scss";

.project-detail {
  .project-img-container {
    cursor: pointer;
    transition: 0.2s;
    max-height: 300px;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .project-img-container:hover {
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  }
  .project-data {
    .project-data-element {
      .project-data-primary {
        color: $primary-color;
        margin-left: 1rem;
      }
    }
  }
  .contact-container {
    margin: 30px 0;
    text-align: center;
  }
  .project-body {
    width: calc(100% - 0.1rem);
  }
}
