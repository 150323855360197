@import "utils/mixins.scss";
@import "utils/colors.scss";

.about {
    @include page-padding;
    .about-top-image-container {
        margin-top: 36px;
        height: 450px;
        object-fit: contain;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .about-top-image {
            width: 100%;
        }
    }
    .about-us-content {
        .about-us-description-container {
            margin: 100px 12px;
            opacity: 0;
            transform: translateX(-20vw);
            visibility: hidden;
            transition:
                opacity 1200ms ease-out,
                transform 600ms ease-out,
                visibility 1200ms ease-out;
            will-change: opacity, transform, visibility;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            .about-us-description-avatar {
                height: 300px;
                width: 300px;
                margin-right: 24px;
            }
        }
        .right-to-left {
            transform: translateX(20vw);
            flex-direction: row-reverse;
            .about-us-description-avatar {
                margin-right: 0;
                margin-left: 24px;
            }
        }
        .about-us-description-container.is-visible {
            opacity: 1;
            transform: none;
            visibility: visible;
        }
    }
}
@media (max-width: 1150px) {
    .about {
        .about-top-image-container {
            height: 350px;
        }
    }
}
@media (max-width: 900px) {
    .about {
        padding: 50px 20px 10px;
        .about-top-image-container {
            margin-left: -20px;
            margin-right: -20px;
            display: flex;
            width: calc(100% + 72px);
            overflow: hidden;
            justify-content: center;
            .about-top-image {
                width: 150vw;
            }
        }
        .about-us-content {
            .about-us-description-container {
                flex-direction: column;
                text-align: center;
                .about-us-description-avatar {
                    margin-bottom: 24px;
                    margin-left: 0;
                    margin-right: 0;
                    width: 150px;
                    height: 150px;
                }
            }
            .right-to-left {
                flex-direction: column;
            }
        }
    }
}
@media (max-width: 550px) {
    .about {
        .about-top-image-container {
            height: 250px;
        }
    }
}
