@import "./colors.scss";

@mixin horizontal-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin white-on-primary {
    color: $white;
    background-color: $primary-color;
}

@mixin primary-on-white {
    color: $primary-color;
    background-color: $white;
}

@mixin white-on-black {
    color: $white;
    background-color: $black;
}

@mixin black-on-white {
    color: $black;
    background-color: $white;
}

@mixin page-padding {
    padding: 50px 36px 10px;
}
